import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import PartyView from "@/views/PartyView";

Vue.config.productionTip = false
Vue.use(VueRouter)

Vue.prototype.$SOCKET_URL = 'wss://omega-surf-prototype.herokuapp.com'

const routes = [
  { path: '/:party_id?', component: PartyView },
]

const router = new VueRouter({
  routes
})

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
