<template>
  <div>
    <div class="flex-box">
      <div class="streams-gallery-container">
        <streams-gallery :local-stream="localStream" ref="streamsGallery">
          <cameras-gallery></cameras-gallery>
        </streams-gallery>
      </div>
      <div class="chat-container">
        <Chat :messages="messages"></Chat>
      </div>
    </div>
  </div>
</template>

<script>
import StreamsGallery from "@/components/StreamsGallery";
import CamerasGallery from "@/components/CamerasGallery";
import Chat from "@/components/Chat";
export default {
  name: "PartyView",
  props: ['localStream', 'messages'],
  components: {Chat, CamerasGallery, StreamsGallery},
  methods: {
    refreshRemoteStream() {
      this.$refs['streamsGallery'].refreshRemoteStream();
    }
  }
}
</script>

<style scoped>
.flex-box {
  display: flex;
  flex-direction: row;
}
.chat-container {
  width: 25rem;
  height: 100vh;
}
.streams-gallery-container {
  height: 100vh;
  width: calc(100vw - 25rem);
}
</style>
