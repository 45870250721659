<template>
  <div class="chat">
    <div class="members-container">

    </div>
    <div class="messages-container">
      <div v-for="(message, i) in messages" :key="i">
        <Message :name="message.name" :message="message.m"></Message>
      </div>
    </div>
    <input-box></input-box>
  </div>
</template>

<script>
import InputBox from "@/components/chat/InputBox";
import Message from "@/components/chat/Message";
export default {
  name: "Chat",
  components: {Message, InputBox},
  props: ['messages'],
}
</script>

<style scoped>
.chat {
  width: 100%;
  height: 100%;
}
.members-container {
  height: 5rem;
  overflow-x: auto;
}
.messages-container {
  height: calc(100% - 10rem);
  overflow-y: auto;
  user-select: unset;
}
</style>
