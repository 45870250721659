<template>
  <div>
    {{ name }}: {{ message }}
  </div>
</template>

<script>
export default {
  name: "Message",
  props: ['name', 'message']
}
</script>

<style scoped>

</style>
