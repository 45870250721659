<template>
  <div class="streams-gallery">
    <slot></slot>
    <div class="no-streams" v-if="!localStream && !remoteStream">
      <div class="brand">Omega</div>
      <div class="big button" @click="shareScreen()" v-if="$root.$children[0].isHost">
        Share screen
      </div>
      <div class="tip" v-if="!$root.$children[0].isHost">Only the party host can share their screen.</div>
      <div class="tip" v-if="$root.$children[0].isHost">You're the party host, so only you can share your screen.</div>
    </div>
    <div class="local-stream-preview-container" v-if="localStream">
      <video class="local-stream-preview" :srcObject.prop="localStream" autoplay></video>
    </div>
    <div class="local-stream-preview-container" v-if="remoteStream">
      <video class="local-stream-preview" :srcObject.prop="remoteStream" autoplay ref="remote-stream"></video>
    </div>
    <div class="controls-container" v-if="localStream">
      <!--<div class="dark small button">Mute</div>
      <div class="dark small button">Start camera</div>-->
      <div class="red small button" @click="stopShare()">Stop sharing</div>
    </div>
    <div class="controls-container-shadow"></div>
  </div>
</template>

<script>
export default {
  name: "StreamsGallery",
  props: ['localStream'],
  data() {
    return {
      remoteStream: false,
    }
  },
  methods: {
    async shareScreen() {
      this.$root.$children[0].localStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        cursor: "always",
        audio: true,
      });
      this.$root.$children[0].localStream.addEventListener('inactive', () => {
        this.$root.$children[0].localStream = undefined;
      });
    },
    stopShare() {
      this.$root.$children[0].localStream.getTracks().forEach((track) => {
        track.stop();
      });
    },
    getRemoteStream() {
      let app = this.$root.$children[0];
      if (!app.screenPeers[app.socket.channelHostId]) {
        return false;
      }
      let stream = new MediaStream();
      app.screenPeers[app.socket.channelHostId].getReceivers().forEach((tx) => {
        stream.addTrack(tx.track);
      });
      return stream;
    },
    refreshRemoteStream() {
      this.remoteStream = this.getRemoteStream();
      this.$nextTick(() => {
        this.$refs['remote-stream'].play();
      });
    }
  }
}
</script>

<style scoped>
.streams-gallery {
  background-color: var(--gallery-background-color);
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.no-streams {
  background-color: var(--hero-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.no-streams>.button {
  margin: 2rem 0;
}
.brand {
  font-weight: bold;
  color: var(--muted-color);
  font-size: 1.5rem;
}
.tip {
  color: var(--muted-color);
}
.local-stream-preview-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.local-stream-preview {
  width: 100%;
  height: 100%;
}
.controls-container {
  position: absolute;
  bottom: -20px;
  width: 100%;
  height: 4.2rem;
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: all var(--transition-time);
  box-shadow: inset 0 -60px 30px -30px #000000;
}
.controls-container > .button {
  margin: 1rem 0.5rem;
  backdrop-filter: blur(18px);
}
.streams-gallery:hover > .controls-container {
  bottom: 0;
  opacity: 1;
}
</style>
