<template>
  <div>
    <input type="text" class="input" placeholder="Message your party" v-model="message" @keydown.enter="sendChat()">
  </div>
</template>

<script>
export default {
  name: "InputBox",
  props: [ 'value' ],
  data() {
    return {
      message: '',
    }
  },
  methods: {
    sendChat() {
      this.$root.$children[0].sendChat(this.message);
      this.message = '';
    }
  }
}
</script>

<style scoped>
.input {
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: var(--light-color);
  width: calc(25rem - 2rem - 1.6rem);
  margin: 1rem;
  height: calc(3rem - 1rem);
  font-size: 1.2rem;
  padding: 0.5rem 0.8rem;
  color: var(--text-color);
}
</style>
