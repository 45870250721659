<template>
  <div id="app">
    <router-view :local-stream="localStream" :messages="messages" ref="view"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      localStream: undefined,
      socket: undefined,
      screenPeers: {},
      connectionToHostState: undefined,
      isHost: false,
      messages: [],
      ICE_SERVERS: [
        {url:'stun:stun01.sipphone.com'},
        {url:'stun:stun.ekiga.net'},
        {url:'stun:stun.fwdnet.net'},
        {url:'stun:stun.ideasip.com'},
        {url:'stun:stun.iptel.org'},
        {url:'stun:stun.rixtelecom.se'},
        {url:'stun:stun.schlund.de'},
        {url:'stun:stun.l.google.com:19302'},
        {url:'stun:stun1.l.google.com:19302'},
        {url:'stun:stun2.l.google.com:19302'},
        {url:'stun:stun3.l.google.com:19302'},
        {url:'stun:stun4.l.google.com:19302'},
        {url:'stun:stunserver.org'},
        {url:'stun:stun.softjoys.com'},
        {url:'stun:stun.voiparound.com'},
        {url:'stun:stun.voipbuster.com'},
        {url:'stun:stun.voipstunt.com'},
        {url:'stun:stun.voxgratia.org'},
        {url:'stun:stun.xten.com'},
      ],
    }
  },
  created() {
    this.socket = new WebSocket(this.$SOCKET_URL);
    this.socket.addEventListener('message', (event) => {
      let message = JSON.parse(event.data);
      //console.log(message);
      if (message.type === 'id') {
        this.socket.id = message.id;
        if (this.$route.params.party_id) {
          this.socket.send(JSON.stringify({
            type: 'j',
            cid: this.$route.params.party_id,
          }));
        }
        else {
          this.socket.send(JSON.stringify({
            type: 'cc',
          }));
        }
      }
      else if (message.type === 'cc') {
        this.socket.send(JSON.stringify({
          type: 'j',
          cid: message.cid,
        }));
      }
      else if (message.type === 'j') {
        if (message.s) {
          this.socket.channelId = message.cid;
          document.title = `#/${message.cid} - Omega`;
          this.socket.channelHostId = message.host;
          this.isHost = this.socket.id === this.socket.channelHostId;
          this.$router.push({ params: { party_id: message.cid } });
        }
        else {
          this.socket.send(JSON.stringify({
            type: 'cc',
          }));
        }
      }
      else if (message.type === 'ap') {
        let peerMap;
        if (message.peerType === 'screen') {
          peerMap = this.screenPeers;
        }
        if (!peerMap || message.id in Object.keys(peerMap)) {
          return;
        }
        peerMap[message.id] = new RTCPeerConnection({
          "iceServers": this.ICE_SERVERS,
        });
        peerMap[message.id].addEventListener('icecandidate', (event) => {
          if (event.candidate) {
            this.socket.send(JSON.stringify({
              type: 'i',
              cid: this.socket.channelId,
              peerType: message.peerType,
              ic: {
                'sdpMLineIndex': event.candidate.sdpMLineIndex,
                'candidate': event.candidate.candidate,
              }
            }));
          }
        });
        peerMap[message.id].addEventListener('connectionstatechange', (event) => {
          this.connectionToHostState = event.target.connectionState;
        });
        if (this.localStream) {
          peerMap[message.id].addTrack(this.localStream.getVideoTracks()[0]);
          if (this.localStream.getAudioTracks()[0]) {
            peerMap[message.id].addTrack(this.localStream.getAudioTracks()[0]);
          }
        }
        if (message.o) {
          // create offer
          console.log('Creating offer...');
          this.screenPeers[message.id].createOffer((localDesc) => {
            console.log('Setting local description...');
            this.screenPeers[message.id].setLocalDescription(localDesc, () => {
              this.socket.send(JSON.stringify({
                type: 's',
                cid: this.socket.channelId,
                peerType: message.peerType,
                sd: localDesc,
              }));
            }, (err) => { console.log('setLocalDescription() failed!'); console.log(err); });
          }, (err) => { console.log('createOffer() failed!');  console.log(err); }, {
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          });
        }
      }
      else if (message.type === 's') {
        let peerMap;
        if (message.peerType === 'screen') {
          peerMap = this.screenPeers;
        }
        if (!peerMap || !peerMap[message.id]) {
          return;
        }
        if (message.id !== this.socket.id) {
          console.log('SD:' + message.id);
          const peer = peerMap[message.id];
          const remoteDesc = new RTCSessionDescription(message.sd);
          console.log('Setting remote description...');
          peer.setRemoteDescription(remoteDesc, () => {
            if (remoteDesc.type === 'offer') {
              console.log('Creating answer...');
              peer.createAnswer((localDesc) => {
                console.log('Setting local description...');
                peer.setLocalDescription(localDesc, () => {
                  this.socket.send(JSON.stringify({
                    type: 's',
                    cid: this.socket.channelId,
                    peerType: message.peerType,
                    fid: message.id,
                    sd: localDesc,
                  }));
                }, () => { console.log("setLocalDescription() failed!") });
              }, () => { console.log("createAnswer() failed!"); });
            }
          }, (err) => { console.log("setRemoteDescription() failed!"); console.log(err); });
        }
      }
      else if (message.type === 'i') {
        let peerMap;
        if (message.peerType === 'screen') {
          peerMap = this.screenPeers;
        }
        if (!peerMap || !peerMap[message.id]) {
          return;
        }
        if (message.id !== this.socket.id) {
          peerMap[message.id].addIceCandidate(new RTCIceCandidate(message.ic));
        }
      }
      else if (message.type === 'rp') {
        let peerMap;
        if (message.peerType === 'screen') {
          peerMap = this.screenPeers;
        }
        if (!peerMap || !peerMap[message.id]) {
          return;
        }
        peerMap[message.id].close();
        delete peerMap[message.id];
        this.$refs['view'].refreshRemoteStream();
      }
      else if (message.type === 'rm') {
        this.screenPeers[message.id].close();
        delete this.screenPeers[message.id];
        this.$refs['view'].refreshRemoteStream();
      }
      else if (message.type === 'so') {
        this.$refs['view'].refreshRemoteStream();
      }
      else if (message.type === 'c') {
        this.messages.push({
          name: message.id,
          m: message.m,
        });
      }
    });
    this.socket.addEventListener('open', () => {
      setInterval(() => {
        this.socket.send('{"type":"p"}');
      }, 10 * 1000);
    });
    this.socket.addEventListener('close', (event) => {
      console.log(event);
    });
  },
  methods: {
    sendChat(message) {
      this.socket.send(JSON.stringify({
        type: 'c',
        cid: this.socket.channelId,
        m: message,
      }));
    },
  },
  watch: {
    localStream(val) {
      if (val === undefined) {
        if (this.socket.readyState === 1) {
          this.socket.send(JSON.stringify({
            type: 'sc',
            peerType: 'screen',
            cid: this.socket.channelId,
          }));
        }
      }
      else {
        if (this.socket.readyState === 1) {
          this.socket.send(JSON.stringify({
            type: 'so',
            peerType: 'screen',
            cid: this.socket.channelId,
          }));
        }
        for (let peer of Object.values(this.screenPeers)) {
          console.log(this.localStream.getVideoTracks()[0]);
          peer.getSenders()[1].replaceTrack(this.localStream.getVideoTracks()[0])
              .catch(err => console.log(err));
          if (this.localStream.getAudioTracks()[0]) {
            peer.getSenders()[0].replaceTrack(this.localStream.getAudioTracks()[0]);
          }
        }
      }
    },
    connectionToHostState(val) {
      if (val === 'connected') {
        console.log('connected');
        this.$refs['view'].refreshRemoteStream();
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
:root {
  --background-color: #03011c;
  --hero-color: #34066B;
  --muted-color: rgba(255, 255, 255, 0.25);
  --muted-color-active: rgba(255, 255, 255, 0.3);
  --light-color: rgba(255, 255, 255, 0.15);
  --light-color-active: rgba(255, 255, 255, 0.25);
  --dark-color: rgba(50, 50, 50, 0.35);
  --dark-color-active: rgba(50, 50, 50, 0.5);
  --red-color: rgba(195, 93, 93, 0.7);
  --red-color-active: rgba(195, 93, 93, 0.9);
  --text-color: #fff;
  --top-nav-height: 5rem;
  --transition-time: 0.1s;
  --gallery-background-color: rgba(0, 0, 0, 0.5);
}
body {
  margin: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  user-select: none;
}
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.button {
  display: inline-block;
  background-color: var(--muted-color);
  transition: all var(--transition-time);
  border-radius: 4px;
  font-size: 1.2rem;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  box-shadow: 0 10px 2rem 0 rgba(0, 0, 0, 0.1);
}
.button:hover {
  background-color: var(--muted-color-active);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.3);
}
.button:active {
  box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.3);
}
.button.red {
  background-color: var(--red-color);
}
.button.red:hover {
  background-color: var(--red-color-active);
}
.button.light {
  background-color: var(--light-color);
}
.button.light:hover {
  background-color: var(--light-color-active);
}
.button.dark {
  background-color: var(--dark-color);
}
.button.dark:hover {
  background-color: var(--dark-color-active);
}
.button.big {
  font-size: 1.5rem;
  padding: 1.2rem 4rem;
}
.button.small {
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 0.2rem 1.5rem;
}
</style>
